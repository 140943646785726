<template>
  <div>
    <div id="tiny-slider-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-6">
              <div v-html="recomendations" class="center-mode"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="save-glossary-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.glossary.text10') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t('views.glossary.text11') }}: {{ lang_inputs[global_lang].name }}
      </h2>
    </div>
    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="lang__tabs nav nav-tabs flex-col sm:flex-row text-gray-600 rounded-b-none" role="tablist">
          <a v-for="(lang, key) in langs" :key="key" data-toggle="tab" @click="changeLang('lang__' + lang.key)"
            :data-target="'#lang__' + lang.key" href="javascript:;"
            class="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center rounded-t-md"
            :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''" :id="'lang__' + lang.key + '-tab'" role="tab"
            :aria-controls="'lang__' + lang.key" aria-selected="false"> {{ $t('components.langs.' + lang.key + '.name')
            }}
          </a>
        </div>
        <div v-for="(lang, key) in langs" :key="key" :class="'post__lang__' + lang.key" class="tab-content">
          <div :id="'lang__' + lang.key" class="tab-pane " :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''"
            role="tabpanel" :aria-labelledby="'lang__' + lang.key + '-tab'">

            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5">
              <div>
                <!-- campo nombre -->
                <label for="crud-form-1" class="form-label">{{ $t('views.glossary.text5') }} </label>
                <div class="input-group">
                  <input id="crud-form-1" type="text" name="chart-tooltip" class="form-control w-full"
                    :placeholder="$t('views.glossary.text5')" v-model="lang_inputs[lang_key].name" />
                </div>
                <div v-if="validations.name" class="text-theme-6 mt-2">
                  {{ $t('views.glossary.text14') }}
                </div>
              </div>
              <!-- campo categoria -->
              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t('views.glossary.text6') }}</label>
                <div class="input-group">
                  <TomSelect ref="category" class="w-full cursor" v-model="term.category"
                    :placeholder="$t('views.glossary.text3')" :plugins="['dropdown_input']">
                    <option value="">{{ $t('views.glossary.text3') }}</option>
                    <option v-for="(cat, key) in categories" :key="key" :value="cat._id">
                      {{ cat.name }}
                    </option>
                  </TomSelect>
                </div>
                <div v-if="validations.category" class="text-theme-6 mt-2">
                  {{ $t('views.glossary.text14') }}
                </div>
              </div>
              <!-- campo definicion -->
              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t('views.glossary.text7') }}</label>
                <div class="input-group">
                  <textarea id="crud-form-3" type="text" class="form-control" :placeholder="$t('views.glossary.text12')"
                    aria-describedby="input-group-1" v-model="lang_inputs[lang_key].description" />
                </div>
                <div v-if="validations.description" class="text-theme-6 mt-2">
                  {{ $t('views.glossary.text14') }}
                </div>
              </div>
              <!-- campo sinonimos -->
              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t('views.glossary.text13')
                  }}</label>
                <div class="input-group w-full">
                  <input id="crud-form-1" type="text" name="chart-tooltip" class="form-control "
                    :placeholder="$t('views.glossary.text13')" aria-describedby="input-group-price"
                    v-model="lang_inputs[lang_key].synonym" v-on:keyup="synonymAdd" @focusout="synonymAdd" />
                  <div id="input-group-p
                  rice" class="input-group-text btn" @click="synonymAdd">
                    <PlusIcon />
                  </div>
                </div>
                <div class="mt-5 synonymsList" v-if="lang_inputs[lang_key].synonyms.length > 0">
                  <div class="flex items-center" v-for="(val, key) in lang_inputs[lang_key].synonyms" :key="key"
                    :value="key">
                    <div class="items-center w-full relative" v-if="val.edit == false">
                      <div class="intro-x">
                        <div class="box border px-5 mb-3 flex items-center">
                          <div class="ml-4 mr-auto">
                            <div class="font-medium">{{ val.text }}</div>
                          </div>
                          <button v-on:click="val.edit = true" type="button"
                            class="ml-5 btn-outline-dark btn-rounded btn-sm">
                            <EditIcon class="btn-source-icon" />
                          </button>
                          <button v-on:click="synonymDelete(val)" type="button"
                            class="btn-outline-dark btn-rounded btn-sm">
                            <Trash2Icon class="btn-source-icon" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="block w-full" v-else>
                      <div class="input-group w-full mb-3">
                        <input id="crud-form-1" type="text" name="synonym" class="form-control "
                          :placeholder="$t('views.glossary.text13')" aria-describedby="input-group-price"
                          v-model="val.text" />
                        <div id="input-group-price" class="input-group-text btn" @click="synonymEdit(val)">
                          <SaveIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="validations.synonyms" class="text-theme-6 mt-2">
                  {{ $t('views.glossary.text14') }}
                </div>
              </div>
              <!-- campo antonimos -->
              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t('views.glossary.text15')
                  }}</label>
                <div class="input-group w-full">
                  <input id="crud-form-1" type="text" name="chart-tooltip" class="form-control "
                    :placeholder="$t('views.glossary.text15')" aria-describedby="input-group-price"
                    v-model="lang_inputs[lang_key].antonym" v-on:keyup="antonymAdd" @focusout="antonymAdd" />
                  <div id="input-group-price" class="input-group-text btn" @click="antonymAdd">
                    <PlusIcon />
                  </div>
                </div>
                <div class="mt-5" v-if="lang_inputs[lang_key].antonyms.length > 0">
                  <div class="flex items-center" v-for="(val, key) in lang_inputs[lang_key].antonyms" :key="key"
                    :value="val.id">
                    <div class="items-center w-full relative" v-if="val.edit == false">
                      <div class="intro-x">
                        <div class="box border px-5 mb-3 flex items-center">
                          <div class="ml-4 mr-auto">
                            <div class="font-medium">{{ val.text }}</div>
                          </div>
                          <button v-on:click="val.edit = true" type="button"
                            class="ml-5 btn-outline-dark btn-rounded btn-sm">
                            <EditIcon class="btn-source-icon" />
                          </button>
                          <button v-on:click="antonymDelete(val)" type="button"
                            class="btn-outline-dark btn-rounded btn-sm">
                            <Trash2Icon class="btn-source-icon" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="block w-full" v-else>
                      <div class="input-group w-full mb-3">
                        <input id="crud-form-1" type="text" name="antonym" class="form-control "
                          :placeholder="$t('views.glossary.text15')" aria-describedby="input-group-price"
                          v-model="val.text" />
                        <div id="input-group-price" class="input-group-text btn" @click="antonymEdit(val)">
                          <SaveIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="validations.antonyms" class="text-theme-6 mt-2">
                  {{ $t('views.glossary.text14') }}
                </div>
              </div>
              <!-- campo estado -->
              <div v-if="vueFillAprobed" class="season mt-6">
                <label for="crud-form-3" class="form-label ">{{
                  $t("views.glossary.text9")
                }}</label>
                <div
                  class="mt-2 gap-4 gap-x-6 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 xl:gap-x-6 xxl:grid-cols-6">
                  <div class="form-check mr-2 mt-2 sm:mt-0">
                    <input id="checkbox-switch-not-review" class="form-check-input" type="radio" name="status"
                      v-model="term.status" value="1" />
                    <label class="form-check-label" for="checkbox-switch-status">{{
                      $t("views.glossary.UNREVIEWED")
                    }}</label>
                  </div>
                  <div class="form-check mr-2 mt-2 sm:mt-0">
                    <input id="checkbox-switch-review" class="form-check-input" type="radio" name="status"
                      v-model="term.status" value="2" />
                    <label class="form-check-label" for="checkbox-switch-status">{{
                      $t("views.glossary.REVIEWED") }}</label>
                  </div>
                </div>
                <div v-if="validations.status" class="text-theme-6 mt-2">
                  {{ $t("views.habits.text20") }}
                </div>
              </div>

              <div class="text-right mt-5">
                <button v-on:click="saveTerm" type="button" class="btn btn-primary w-24">
                  <LoadingIcon v-if="loading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.save') }}</span>
                </button>
              </div>
            </div>
            <!-- END: Form Layout -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dark .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}

.mt-3 {
  color: #fff;
}

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.max-w-label {
  max-width: 90%;
}

.text-sm-14 {
  font-size: 0.875rem;
}

.btn-source-icon {
  width: 1rem;
}

.btn-source-icon.w-6 {
  width: 1.5rem;
}

.w-50 {
  width: 50%;
}

.my-custom-input {
  display: none !important;
}

.transparent {
  color: transparent;
}

.benefits_item:last-child .remove-item {
  visibility: hidden;
}

.benefits_item:nth-child(2) {
  margin-top: 0px;
}
</style>
<script>
import GlossaryService from "../../services/glossary.service"
import Toastify from "toastify-js"

export default {
  setup() { },
  data() {
    return {
      validations: {
        name: false,
        description: false,
        synonyms: false,
        antonyms: false,
        category: false,
        lenguages: false
      },
      loading: false,
      recomendations: null,
      queryUrl: this.$route.query,
      hiddenKey: [
        "id",
        "id_api",
        "food_id",
        "updatedAt",
        "createdAt",
        "deletedAt"
      ],
      term: localStorage.glossaryItem
        ? JSON.parse(localStorage.glossaryItem)
        : {
          name: "",
          description: "",
          category_id: '',
          status: "",
          synonyms: [],
          antonyms: [],
          languages: []
        },
      vueFillAprobed: false,
      termsList: [],
      categories: [],
      activeLang: 'lang__' + (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      langs: this.$global.getLangs(),
      lang_key: (localStorage.lang) ? localStorage.lang : (navigator.language).split("-")[0],
      global_lang: (localStorage.lang) ? localStorage.lang : (navigator.language).split("-")[0],
      lang_inputs: [],
      status: this.$global.getStatus(),
      getData: true,
      editMode: false // edicion o creacion
    }
  },
  watch: {
    termsCategories: function () {
      var categories = this.categories
      this.termsCategories.forEach(function (c) {
        var cat = categories.find(function (cc) {
          return cc.id == c
        })
        if (!cat) {
          categories.push({ id: c, name: c, new: true })
        }
      })
      this.categories = categories
    },
    '$i18n.locale': function (l) {
      this.getAll()
      this.getAllCategories()
    },
    activeLang: function () {
      this.lang_key = this.activeLang.replace("lang__", "")
    }
  },
  computed: {
  },
  created() {
    this.verifiedAccess()
    this.lang_inputs = this.langs.map(l => l.key).reduce((x, y) => (x[y] = { name: "", description: "", synonyms: [], synonym: "", antonyms: [], antonym: "" }, x), {})
    this.lang_inputs[this.lang_key]["name"] = this.term["name"]
    this.getAllCategories()
    this.getTerm()
  },
  methods: {
    verifiedAccess() {
      const permissions = localStorage.backoffice_permissions
        ? JSON.parse(localStorage.backoffice_permissions)
        : []

      const approvedPermission = permissions.find((p) => p.name == "GLOSSARY_ADMIN")
      if (approvedPermission) this.vueFillAprobed = true
      else {
        this.vueFillAprobed = false
      }
    },
    getTerm: async function () {
      var term = this.term

      if (term && term._id) {
        this.editMode = true //para edicion
        try {
          this.loading = true

          //  Asignar datos del término principal (en español por defecto)
          this.lang_inputs['es'] = {
            name: term.name || '',
            description: term.description || '',
            synonyms: term.synonyms ? term.synonyms.map(syn => ({ text: syn })) : [],
            antonyms: term.antonyms ? term.antonyms.map(ant => ({ text: ant })) : [],
            synonym: '',
            antonym: ''
          }

          // Recorrer los idiomas traducidos en `languages` y asignar los datos correspondientes
          term.languages.forEach(language => {
            const langCode = language.language_code
            if (this.lang_inputs[langCode]) {
              this.lang_inputs[langCode] = {
                name: language.translated_name || '',
                description: language.translated_description || '',
                synonyms: language.translated_synonyms ? language.translated_synonyms.map(syn => ({ text: syn })) : [],
                antonyms: language.translated_antonyms ? language.translated_antonyms.map(ant => ({ text: ant })) : [],
                synonym: '',
                antonym: ''
              }
            }
          })

          //  Asignar categoría y estado del término principal
          this.term.category = term.category_id._id || ''
          this.term.status = term.status

          this.getData = false
        } catch (error) {
          console.error("Error al obtener el término:", error)
        } finally {
          this.loading = false
        }
      } else {
        this.editMode = false
        if (!this.term.status) {
          this.term.status = 1
          console.log(this.term.status)
        }
      }
    },
    synonymAdd: function (event) {

      if (this.lang_inputs[this.lang_key].synonym != "" && (event.key == "Enter" || event.pointerId || event.type == "focusout")) {
        this.lang_inputs[this.lang_key].synonyms.push({ id: null, text: this.lang_inputs[this.lang_key].synonym, edit: false, error: false })
        this.lang_inputs[this.lang_key].synonym = ""
      }
    },
    synonymEdit: function (val) {
      if (val.text !== "") {
        val.edit = false
        val.error = false
      } else {
        val.error = true
      }
    },
    synonymDelete: function (val) {
      const index = this.lang_inputs[this.lang_key].synonyms.findIndex((s) => s === val)
      if (index !== -1) {
        this.lang_inputs[this.lang_key].synonyms.splice(index, 1)
      }
    },
    antonymAdd: function (event) {
      if (this.lang_inputs[this.lang_key].antonym != "" && (event.key == "Enter" || event.pointerId || event.type == "focusout")) {
        this.lang_inputs[this.lang_key].antonyms.push({ id: null, text: this.lang_inputs[this.lang_key].antonym, edit: false, error: false })
        this.lang_inputs[this.lang_key].antonym = ""
      }
    },
    antonymEdit: function (val) {
      if (val.text !== "") {
        val.edit = false
        val.error = false
      } else {
        val.error = true
      }
    },
    antonymDelete: function (val) {
      const index = this.lang_inputs[this.lang_key].antonyms.findIndex((s) => s === val)
      if (index !== -1) {
        this.lang_inputs[this.lang_key].antonyms.splice(index, 1)
      }
    },
    addTranslation(langCode) {
      const translationExists = this.term.languages.find(
        lang => lang.language_code === langCode
      )
      if (!translationExists) {
        this.term.languages.push({
          language_code: langCode,
          translated_name: '',
          translated_description: '',
          translated_synonym: [],
          translated_antonym: []
        })
      }
    },
    getAllCategories: function (lang) {
      GlossaryService.getAllCategories(lang).then((response) => {
        this.categories = response.categories
      })
    },
    saveSuccessTerm() {
      Toastify({
        node: cash("#save-glossary-success").clone().removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true
      }).showToast()
    },
    async saveTerm() {
      this.validate()

      if (this.validations.name || this.validations.description || this.validations.category || this.validations.synonyms || this.validations.antonyms) {
        return
      }
      this.loading = true

      // Construir el objeto 'languages' de forma dinámica basado en los idiomas en 'lang_inputs'
      let languages = []
      var l_keys = Object.keys(this.lang_inputs)

      //formateo sinonimos español en solos string
      var synonymsFormattedEs = []
      this.lang_inputs.es.synonyms.forEach(synonym => {
        synonymsFormattedEs.push(synonym.text)
      })

      //formateo antonimos español en solos string
      var antonymsFormattedEs = []
      this.lang_inputs.es.antonyms.forEach(antonym => {
        antonymsFormattedEs.push(antonym.text)
      })

      for (let i = 0; i < l_keys.length; i++) {
        let lang = l_keys[i]
        let langInput = this.lang_inputs[lang]

        if (lang != "es") {

          //formateo sinonimos varios idiomomas excepto es, solos string
          var synonymsFormatted = []
          langInput.synonyms.forEach(synonym => {
            synonymsFormatted.push(synonym.text)
          })

          //formateo antonimos varios idiomomas excepto es, solos string
          var antonymsFormatted = []
          langInput.antonyms.forEach(antonym => {
            antonymsFormatted.push(antonym.text)
          })

          // Crear el objeto de idioma
          let languageData = {
            language_code: lang,
            translated_name: langInput.name,
            translated_description: langInput.description,
            translated_synonyms: synonymsFormatted,
            translated_antonyms: antonymsFormatted
          }

          // Agregar al array de idiomas
          languages.push(languageData)
        }
      }

      var termsSaved = {
        name: this.lang_inputs.es.name,
        description: this.lang_inputs.es.description,
        category_id: this.term.category,
        status: Number(this.term.status),
        synonyms: synonymsFormattedEs,
        antonyms: antonymsFormattedEs,
        languages: languages
      }

      try {
        var serverResponse = {}
        if (this.editMode) { // es edicion
          serverResponse = await GlossaryService.updateTerm(this.term._id, termsSaved)
        } else { // es creacion
          serverResponse = await GlossaryService.createTerm(termsSaved)
        }
        this.saveSuccessTerm()
        setTimeout(() => {
          this.$router.push({ path: "/glossary-list" })
        }, 1500)
      } catch (error) {
        console.error("Error al guardar el término:", error)
      } finally {
        this.loading = false
      }
    },
    validate() {
      this.validations.name = this.lang_inputs[this.lang_key].name === ''
      this.validations.description = this.lang_inputs[this.lang_key].description === ''
      this.validations.category = this.term.category === ''
      this.validations.synonyms = this.lang_inputs[this.lang_key].synonyms.length === 0
      this.validations.antonyms = this.lang_inputs[this.lang_key].antonyms.length === 0
      this.validations.languages = this.term.languages.length === 0
    },
    changeLang(l) {
      this.activeLang = l
    }
  }
}
</script>
<style>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.2 !important;
  /* Firefox */
}
</style>
